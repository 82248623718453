<template>
  <div class="audience-interests d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      Audience Interests
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft d-flex flex-column justify-between flex-grow-1">
      <v-card-text class="flex-grow-1 pb-0">
        <v-list dense>
          <v-list-item
            v-for="(item, index) in interests"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between mb-1">
                {{ item.label }}

                <span class="secondary--text">
                  {{ fPercent(item.weight) }}
                </span>
              </v-list-item-title>

              <v-progress-linear
                color="blue"
                :value="item.weight"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          small
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- the dialog table -->
    <v-dialog
      v-model="shouldShowDialog"
      width="1100"
      scrollable
    >
      <v-card>
        <v-card-title>
          Audience Interests
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            disable-pagination
          >
            <!-- For the label part, show an icon as well -->
            <template v-slot:item.label="{ item }">
              <v-icon
                v-if="interestIconsMap[item.label]"
                color="secondary"
                class="mr-3"
              >
                {{ interestIconsMap[item.label] }}
              </v-icon>

              <span>
                {{ item.label }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import the helper key value map for icons
import interestIconsMap from '@/helpers/interestIconsMap'

// Export the SFc
export default {
  // Name of the component
  name: "AudienceInterests",

  // Accept incoming data from parent
  props: {
    allData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },

    totalValues: {
      type: Object,
      required: true
    },

    allTabs: {
      type: Array,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    interestIconsMap,
    shouldShowDialog: false,

    tableHeaders: [
      {
        text: "Interest",
        value: "label",
        sortable: true
      },
      {
        text: "Followers",
        value: "followers",
        sortable: true
      },
      {
        text: "Likes",
        value: "likes",
        sortable: true
      },
      {
        text: "Followers' Affinity",
        value: "followersAffinity",
        sortable: true,
        align: "end"
      },
      {
        text: "Engagers' Affinity",
        value: "likesAffinity",
        sortable: true,
        align: "end"
      },
    ]
  }),

  // Define computable data variables
  computed: {
    /**
     * Get all the items to be shown in the table view
     *
     * @returns {Array}
     */
    tableItems() {
      const finalArray = []

      for (const tab of this.allTabs) {
        for (const iteratorItem of this.allData[tab]["interests"]) {
          // check if an item with text is not already there, add a default one
          if (finalArray.findIndex((search) => search.label === iteratorItem.label) === -1) {
            finalArray.push({
              label: iteratorItem.label,
              followers: "",
              followersAffinity: "",
              likes: "",
              likesAffinity: ""
            })
          }

          // now find the item and update it
          const foundItem = finalArray.find((search) => search.label === iteratorItem.label)

          foundItem[tab] = `${numeral.fNumber( this.totalValues[tab] * iteratorItem.weight / 100 )} / ${numeral.fPercent(iteratorItem.weight)}`
          foundItem[`${tab}Affinity`] = iteratorItem.affinity.toFixed(2)
        }
      }

      return finalArray
    },

    /**
     * Get all the interests to be shown in chips
     *
     * @returns {Array}
     */
    interests() {
      return this.audienceData.interests
        .map((item) => ({
          ...item,
          affinity: item.affinity.toFixed(2),
          value: `${numeral.fNumber( this.totalValues[this.selectedTab] * item.weight / 100 )}/${numeral.fPercent(item.weight)}`
        }))
        .slice(0, 6)
    }
  }
}
</script>
